import { AMaterialLessonBlockTemplate } from "../../models";

const images = ["/images/figma_image_rect.png"];
const text = {
  title: {
    sample: "this is a text title",
    size: "61px",
    color: "var(--primary)",
  },
  subtitle: {
    sample: "this is a text subtitle",
    size: "14px",
    color: "var(--accent)",
  },
  desc: {
    sample: "this is a text description",
    size: "16px",
    color: "black",
  },
};

export const SectionIntro: AMaterialLessonBlockTemplate[] = [
  // Section Intro 1
  {
    name: "Section Intro 1",
    template: {
      name: "Section Intro 1",
      groups: [
        // upper side:
        {
          items: [
            {
              type: "image",
              value: images[0],
              style: {
                width: "100%",
                height: "100%",
              },
            },
          ],
          style: {
            width: "100%",
            height: "52%",
          },
        },
        // lower side:
        {
          items: [
            {
              type: "text",
              value: text.title.sample,
              style: {
                fontWeight: "bold",
                color: text.title.color,
                fontSize: text.title.size,
                position: "absolute",
                left: "0px",
                top: "0px",
                width: "40%",
                height: "50%",
              },
            },
            {
              type: "text",
              value: text.subtitle.sample,
              style: {
                fontWeight: "bold",
                color: text.subtitle.color,
                fontSize: text.subtitle.size,
                height: "30%",
                position: "absolute",
                left: "0px",
                bottom: "0px",
                width: "40%",
                display: "inline-block",
                padding: "10px",
              },
            },
            {
              type: "text",
              value: text.desc.sample,
              style: {
                color: text.desc.color,
                fontSize: text.desc.size,
                height: "100%",

                position: "absolute",
                right: "0px",
                top: "0px",
                width: "60%",
              },
            },
          ],
          style: {
            width: "100%",
            height: "50%",

            display: "flex",
            justifySelf: "flex-start",
            alignSelf: "flex-start",
            justifyContent: "space-between",
            position: "relative",
          },
        },
      ],
      style: {
        display: "flex",
        flexDirection: "column",
        gap: "17px",
        width: "100%",
        height: "100%",
      },
    },
  },

  // Section Intro 2 - Same order of Section Intro 1, but used flexDirection Column-reverse
  {
    name: "Section Intro 2",
    template: {
      name: "Section Intro 2",
      groups: [
        // Lower side:
        {
          items: [
            {
              type: "image",
              value: images[0],
              style: {
                width: "100%",
                height: "100%",
              },
            },
          ],
          style: {
            width: "100%",
            height: "52%",
          },
        },
        // Upper side:
        {
          items: [
            {
              type: "text",
              value: text.title.sample,
              style: {
                fontWeight: "bold",
                color: text.title.color,
                fontSize: text.title.size,
                position: "absolute",
                left: "0px",
                top: "0px",
                width: "40%",
                height: "50%",
              },
            },
            {
              type: "text",
              value: text.subtitle.sample,
              style: {
                fontWeight: "bold",
                color: text.subtitle.color,
                fontSize: text.subtitle.size,
                height: "30%",
                position: "absolute",
                left: "0px",
                bottom: "0px",
                width: "40%",
              },
            },
            {
              type: "text",
              value: text.desc.sample,
              style: {
                color: text.desc.color,
                fontSize: text.desc.size,
                height: "100%",

                position: "absolute",
                right: "0px",
                top: "0px",
                width: "60%",
              },
            },
          ],
          style: {
            width: "100%",
            height: "50%",

            display: "flex",
            justifySelf: "flex-start",
            alignSelf: "flex-start",
            justifyContent: "space-between",
            position: "relative",
          },
        },
      ],
      style: {
        display: "flex",
        flexDirection: "column-reverse",
        gap: "17px",
        width: "100%",
        height: "100%",
      },
    },
  },

  // Section intro 3:
  {
    name: "Section intro 3",
    template: {
      name: "Section intro 3",
      groups: [
        // left side:
        {
          items: [
            {
              type: "image",
              value: images[0],
              style: {
                width: "100%",
                height: "100%",
              },
            },
          ],
          style: {
            width: "50%",
            height: "100%",
          },
        },
        // right side:
        {
          items: [
            {
              type: "text",
              value: text.title.sample,
              style: {
                fontWeight: "bold",
                color: text.title.color,
                fontSize: text.title.size,
                height: "fit-content",
              },
            },
            {
              type: "text",
              value: text.subtitle.sample,
              style: {
                fontWeight: "bold",
                color: text.subtitle.color,
                fontSize: text.subtitle.size,
              },
            },
            {
              type: "text",
              value: text.desc.sample,
              style: {
                // fontWeight: "bold",
                color: text.desc.color,
                fontSize: text.desc.size,
                height: "fit-content",

                marginTop: "20px",
              },
            },
          ],
          style: {
            width: "50%",
            height: "100%",

            display: "flex",
            flexDirection: "column",
            justifySelf: "flex-start",
            alignSelf: "flex-start",
          },
        },
      ],
      style: {
        display: "flex",
        flexDirection: "row",
        gap: "30px",
        justifyContent: "space-between",
        width: "100%",
        height: "100%",
      },
    },
  },

  // Section intro 4:
  {
    name: "Section intro 4",
    template: {
      name: "Section intro 4",
      groups: [
        // left side:
        {
          items: [
            {
              type: "image",
              value: images[0],
              style: {
                width: "100%",
                height: "100%",
              },
            },
          ],
          style: {
            width: "60%",
            height: "100%",
          },
        },
        // right side:
        {
          items: [
            {
              type: "text",
              value: text.title.sample,
              style: {
                fontWeight: "bold",
                color: text.title.color,
                fontSize: text.title.size,
                height: "fit-content",
              },
            },
            {
              type: "text",
              value: text.subtitle.sample,
              style: {
                fontWeight: "bold",
                color: text.subtitle.color,
                fontSize: text.subtitle.size,
              },
            },
            {
              type: "text",
              value: text.desc.sample,
              style: {
                // fontWeight: "bold",
                color: text.desc.color,
                fontSize: text.desc.size,
                height: "fit-content",

                marginTop: "20px",
              },
            },
          ],
          style: {
            width: "40%",
            height: "100%",

            display: "flex",
            flexDirection: "column",
            justifySelf: "flex-start",
            alignSelf: "flex-start",
          },
        },
      ],
      style: {
        display: "flex",
        flexDirection: "row",
        gap: "20px",
        justifyContent: "space-between",
        width: "100%",
        height: "100%",
      },
    },
  },

  // Section intro 5:
  {
    name: "Section intro 5",
    template: {
      name: "Section intro 5",
      groups: [
        // left side:
        {
          items: [
            {
              type: "text",
              value: text.title.sample,
              style: {
                fontWeight: "bold",
                color: text.title.color,
                fontSize: text.title.size,
                height: "fit-content",
              },
            },
            {
              type: "text",
              value: text.subtitle.sample,
              style: {
                fontWeight: "bold",
                color: text.subtitle.color,
                fontSize: text.subtitle.size,
              },
            },
            {
              type: "text",
              value: text.desc.sample,
              style: {
                // fontWeight: "bold",
                color: text.desc.color,
                fontSize: text.desc.size,
                height: "fit-content",

                marginTop: "20px",
              },
            },
          ],
          style: {
            width: "50%",
            height: "100%",

            display: "flex",
            flexDirection: "column",
            justifySelf: "flex-start",
            alignSelf: "flex-start",
          },
        },
        // right side:
        {
          items: [
            {
              type: "image",
              value: images[0],
              style: {
                width: "100%",
                height: "100%",
              },
            },
          ],
          style: {
            width: "50%",
            height: "100%",
          },
        },
      ],
      style: {
        display: "flex",
        flexDirection: "row",
        gap: "30px",
        justifyContent: "space-between",
        width: "100%",
        height: "100%",
      },
    },
  },

  // Section intro 6:
  {
    name: "Section intro 6",
    template: {
      name: "Section intro 6",
      groups: [
        // left side:
        {
          items: [
            {
              type: "text",
              value: text.title.sample,
              style: {
                fontWeight: "bold",
                color: text.title.color,
                fontSize: text.title.size,
                height: "fit-content",
              },
            },
            {
              type: "text",
              value: text.subtitle.sample,
              style: {
                fontWeight: "bold",
                color: text.subtitle.color,
                fontSize: text.subtitle.size,
              },
            },
            {
              type: "text",
              value: text.desc.sample,
              style: {
                // fontWeight: "bold",
                color: text.desc.color,
                fontSize: text.desc.size,
                height: "fit-content",

                marginTop: "20px",
              },
            },
          ],
          style: {
            width: "40%",
            height: "100%",

            display: "flex",
            flexDirection: "column",
            justifySelf: "flex-start",
            alignSelf: "flex-start",
          },
        },

        // right side:
        {
          items: [
            {
              type: "image",
              value: images[0],
              style: {
                width: "100%",
                height: "100%",
              },
            },
          ],
          style: {
            width: "60%",
            height: "100%",
          },
        },
      ],
      style: {
        display: "flex",
        flexDirection: "row",
        gap: "20px",
        justifyContent: "space-between",
        width: "100%",
        height: "100%",
      },
    },
  },
];
